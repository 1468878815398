/* istanbul ignore file */
import {
  createStylesParam,
  createStylesParams,
  IStyleParam,
  IStyleParamValuesByType,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {
  DEFAULT_ONE_TIME_PURCHASE_OPTION_LOCATION,
  GalleryNavigationLocation,
  GalleryNavigationPosition,
  GalleryNavigationType,
  InfoSectionLayoutId,
  LayoutId,
  PaymentOptionsBreakdownTheme,
} from '../../productPage/constants';

export type IStylesParams = {
  [P in keyof StylesParamKeys]: IStyleParam<StylesParamKeys[P]>;
};

export type IStylesParamsValues = {
  [P in keyof StylesParamKeys]: IStyleParamValuesByType[StylesParamKeys[P]];
};

type StylesParamKeys = {
  full_width: StyleParamType.Boolean;
  productPage_buyNowButtonEnabled: StyleParamType.Boolean;
  productPage_galleryZoom: StyleParamType.Boolean;
  productPage_infoSectionShowDeviders: StyleParamType.Boolean;
  productPage_navigation: StyleParamType.Boolean;
  productPage_openMinicart: StyleParamType.Boolean;
  productPage_paymentOptionsBreakdown: StyleParamType.Boolean;
  productPage_productAction: StyleParamType.Boolean;
  productPage_productInfoSection: StyleParamType.Boolean;
  productPage_stockIndicator: StyleParamType.Boolean;
  productPage_productPrice: StyleParamType.Boolean;
  productPage_showDiscountName: StyleParamType.Boolean;
  productPage_productQuantity: StyleParamType.Boolean;
  productPage_productSku: StyleParamType.Boolean;
  productPage_socialNetworkwhatsapp: StyleParamType.Boolean;
  productPage_socialNetworkfacebook: StyleParamType.Boolean;
  productPage_socialNetworkpinterest: StyleParamType.Boolean;
  productPage_socialNetworks: StyleParamType.Boolean;
  productPage_socialNetworktwitter: StyleParamType.Boolean;
  productPage_wishlistEnabled: StyleParamType.Boolean;
  productPage_dynamicPaymentMethodsEnabled: StyleParamType.Boolean;
  responsive: StyleParamType.Boolean;

  productPage_descriptionVisibleLineNumbers: StyleParamType.Number;
  productPage_galleryImageRatio: StyleParamType.Number;
  productPage_galleryImageMode: StyleParamType.Number;
  productPage_layoutId: StyleParamType.Number;
  productPage_infoSectionTypeId: StyleParamType.Number;
  productPage_infoSectionColumnNumber: StyleParamType.Number;
  productPage_subscriptionPlansOneTimePurchase: StyleParamType.Number;
  productPage_paymentOptionsBreakdownTheme: StyleParamType.Number;
  productPage_dynamicPaymentMethodsButtonTheme: StyleParamType.Number;
  productPage_dynamicPaymentMethodsButtonShape: StyleParamType.Number;
  productPage_addToCartAction: StyleParamType.Number;

  productPage_galleryNavigationLocation: StyleParamType.Font;
  productPage_galleryNavigationPosition: StyleParamType.Font;
  productPage_galleryNavigationType: StyleParamType.Font;
  productPage_infoSectionAlignment: StyleParamType.Font;
  productPage_descriptionFontStyle: StyleParamType.Font;
  productPage_descriptionFontStyleOld: StyleParamType.Font;
  productPage_paragraphTitleFontStyle: StyleParamType.Font;
  quickView_infoSectionTitleFontStyle: StyleParamType.Font;
  productPage_infoSectionTitleFontStyle: StyleParamType.Font;
  productPage_paragraphTextFontStyle: StyleParamType.Font;
  productPage_productDetailsAlignment: StyleParamType.Font;
  productPage_stockIndicatorFontStyle: StyleParamType.Font;
  productPage_quantityFontStyle: StyleParamType.Font;
  quickView_descriptionFontStyle: StyleParamType.Font;
  quickView_discountNameFont: StyleParamType.Font;
  quickView_preOrderMessageFontStyle: StyleParamType.Font;
  productPage_optionsFontStyle: StyleParamType.Font;
  quickView_qtyAndOptionsFontStyle: StyleParamType.Font;
  productPage_discountNameFont: StyleParamType.Font;

  const_productPage_PageTitle_16px: StyleParamType.Font;
  const_productPage_PageTitle_32px: StyleParamType.Font;
  const_productPage_BodyM_12px: StyleParamType.Font;
  const_productPage_BodyM_14px: StyleParamType.Font;
  const_productPage_BodyM_16px: StyleParamType.Font;
  const_productPage_BodyM_20px: StyleParamType.Font;
  const_productPage_BodyM_24px: StyleParamType.Font;
  const_productPage_BodyM_26px: StyleParamType.Font;
  const_productPage_BodyM_32px: StyleParamType.Font;
  productPage_descriptionTextColor: StyleParamType.Color;
  productPage_paragraphTextColor: StyleParamType.Color;
  quickView_descriptionTextColor: StyleParamType.Color;
};

const responsive = createStylesParam('responsive', {
  type: StyleParamType.Boolean,
  getDefaultValue: ({isEditorX}) => isEditorX,
});

const quickView_qtyAndOptionsFontStyle = createStylesParam('quickView_qtyAndOptionsFontStyle', {
  type: StyleParamType.Font,
});

const quickView_descriptionFontStyle = createStylesParam('quickView_descriptionFontStyle', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M'),
});

const productPage_paragraphTextColor = createStylesParam('productPage_paragraphTextColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

const quickView_descriptionTextColor = createStylesParam('quickView_descriptionTextColor', {
  type: StyleParamType.Color,
  getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(productPage_paragraphTextColor),
});

const stylesParams: IStylesParams = {
  full_width: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  productPage_buyNowButtonEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  productPage_galleryZoom: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_infoSectionShowDeviders: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_navigation: {
    type: StyleParamType.Boolean,
    getDefaultValue: ({getStyleParamValue}) => !getStyleParamValue(responsive),
  },
  productPage_openMinicart: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_paymentOptionsBreakdown: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_productAction: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_productInfoSection: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_stockIndicator: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  productPage_productPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_showDiscountName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_productQuantity: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_productSku: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_socialNetworkwhatsapp: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_socialNetworkfacebook: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_socialNetworkpinterest: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_socialNetworks: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_socialNetworktwitter: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  productPage_wishlistEnabled: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  productPage_dynamicPaymentMethodsEnabled: {
    type: StyleParamType.Boolean,
  },
  responsive,

  productPage_descriptionVisibleLineNumbers: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  productPage_galleryImageRatio: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => -1,
  },
  productPage_galleryImageMode: {
    type: StyleParamType.Number,
    getDefaultValue: () => ImageModeValues.CROP,
  },
  productPage_layoutId: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: ({getStyleParamValue, isMobile}) =>
      isMobile || getStyleParamValue(responsive) ? LayoutId.Responsive : LayoutId.Classic,
  },
  productPage_infoSectionTypeId: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => InfoSectionLayoutId.Collapse,
  },
  productPage_infoSectionColumnNumber: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: ({isMobile}) => (isMobile ? 3 : 1),
  },
  productPage_subscriptionPlansOneTimePurchase: {
    type: StyleParamType.Number,
    getDefaultValue: () => DEFAULT_ONE_TIME_PURCHASE_OPTION_LOCATION,
  },
  productPage_paymentOptionsBreakdownTheme: {
    type: StyleParamType.Number,
    getDefaultValue: () => PaymentOptionsBreakdownTheme.LIGHT,
  },
  productPage_dynamicPaymentMethodsButtonTheme: {
    type: StyleParamType.Number,
  },
  productPage_addToCartAction: {
    type: StyleParamType.Number,
  },
  productPage_dynamicPaymentMethodsButtonShape: {
    type: StyleParamType.Number,
  },

  productPage_galleryNavigationLocation: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: GalleryNavigationLocation.OUTSIDE,
    }),
  },
  productPage_galleryNavigationPosition: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: GalleryNavigationPosition.BOTTOM,
    }),
  },
  productPage_galleryNavigationType: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: ({isMobile}) => ({
      fontStyleParam: false,
      value: isMobile ? GalleryNavigationType.DOTS : GalleryNavigationType.THUMBNAILS,
    }),
  },
  productPage_infoSectionAlignment: {
    type: StyleParamType.Font,
  },
  quickView_descriptionFontStyle,
  productPage_descriptionFontStyleOld: createStylesParam('productPage_descriptionFontStyle', {
    type: StyleParamType.Font,
  }),
  productPage_descriptionFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(quickView_descriptionFontStyle),
  },
  productPage_infoSectionTitleFontStyle: {
    type: StyleParamType.Font,
  },
  quickView_infoSectionTitleFontStyle: {
    type: StyleParamType.Font,
  },
  productPage_paragraphTitleFontStyle: {
    type: StyleParamType.Font,
  },
  productPage_productDetailsAlignment: {
    type: StyleParamType.Font,
  },
  productPage_paragraphTextFontStyle: {
    type: StyleParamType.Font,
  },
  quickView_discountNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  productPage_quantityFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(quickView_qtyAndOptionsFontStyle),
  },
  productPage_stockIndicatorFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(quickView_qtyAndOptionsFontStyle),
  },
  productPage_optionsFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(quickView_qtyAndOptionsFontStyle),
  },
  quickView_qtyAndOptionsFontStyle,
  quickView_preOrderMessageFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  productPage_discountNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  const_productPage_PageTitle_16px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
    }),
  },
  const_productPage_PageTitle_32px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  const_productPage_BodyM_14px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  const_productPage_BodyM_20px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  const_productPage_BodyM_12px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 12,
    }),
  },
  const_productPage_BodyM_16px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  const_productPage_BodyM_24px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 24,
    }),
  },
  const_productPage_BodyM_26px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 26,
    }),
  },
  const_productPage_BodyM_32px: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 32,
    }),
  },
  productPage_paragraphTextColor,
  quickView_descriptionTextColor,
  productPage_descriptionTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(quickView_descriptionTextColor),
  },
};
export default createStylesParams<StylesParamKeys>(stylesParams);
