import * as React from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {withGlobalProps} from '../../providers/globalPropsProvider';
import {
  ProductDescription as LegacyProductDescription,
  ProductDescriptionProps,
} from './LegacyProductDescription/ProductDescription';
import {useRef, useState} from 'react';
import {SPECS} from '../../specs';
import s from './ProductDescription.scss';
import stylesParams from '../../../components/ProductPage/stylesParams';
import {useStyles} from '@wix/tpa-settings/react';
import {isRicoRichContent} from '../RichContentViewer/utils';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {LazyRichContentViewer} from '../RichContentViewer/LazyRichContentViewer';

export enum ProductDescriptionDataHook {
  DescriptionWrapper = 'description-wrapper',
  Description = 'description',
  ShowMoreOrLess = 'show-more-or-less',
}

export const ProductDescription = withGlobalProps(
  withTranslations('globals.texts')((props: ProductDescriptionProps) => {
    const {description} = props;
    const {experiments} = useExperiments();

    const legacyRichContent =
      !experiments.enabled(SPECS.PRODUCT_PAGE_RICO_DESCRIPTION) || !isRicoRichContent(description);

    if (legacyRichContent) {
      return <LegacyProductDescription {...props} />;
    }

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isNeedToCollapse, setIsNeedToCollapse] = useState(false);

    const styles = useStyles();
    const fontSize = styles.get(stylesParams.productPage_descriptionFontStyle)?.size;
    const lineHeight = Math.ceil(fontSize * 1.5);
    const descriptionVisibleLineNumbers = styles.get(stylesParams.productPage_descriptionVisibleLineNumbers);
    const collapsedContentHeight = lineHeight * descriptionVisibleLineNumbers;
    const showMoreOrLessEnabled = styles.get(stylesParams.productPage_descriptionVisibleLineNumbers) > 2;
    const ref = useRef<HTMLDivElement>();

    const getMaxHeight = (): string => {
      if (showMoreOrLessEnabled && isCollapsed) {
        return `${collapsedContentHeight}px`;
      }

      return '';
    };

    const getShowMoreOrLess = () => {
      const {t} = props;

      return (
        showMoreOrLessEnabled &&
        isNeedToCollapse && (
          <button
            type="button"
            aria-hidden="true"
            className={s.showMoreOrLess}
            onClick={() => setIsCollapsed(!isCollapsed)}
            data-hook={ProductDescriptionDataHook.ShowMoreOrLess}>
            {isCollapsed ? t('DESCRIPTION_SHOW_MORE') : t('DESCRIPTION_SHOW_LESS')}
          </button>
        )
      );
    };

    const onLoad = () => {
      if (ref?.current?.scrollHeight > collapsedContentHeight) {
        setIsNeedToCollapse(true);
      }
    };

    return (
      <>
        <div
          data-hook={ProductDescriptionDataHook.DescriptionWrapper}
          className={s.richContent}
          ref={ref}
          style={{maxHeight: getMaxHeight(), lineHeight: `${lineHeight}px`}}>
          <LazyRichContentViewer
            dataHook={ProductDescriptionDataHook.Description}
            contentAsString={description}
            ricosContext="product description"
            onLoad={onLoad}
          />
        </div>
        {getShowMoreOrLess()}
      </>
    );
  })
);
